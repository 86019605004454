type observeDarkModeHandler = (darkMode: boolean) => void
export function observeDarkMode(handler: observeDarkModeHandler): (() => void) | null {
  if (!handler) return null

  function listener(ev: MediaQueryListEvent): void {
    if (ev) {
      const darkMode = ev.matches
      handler(darkMode)
    }
  }

  handler(window.matchMedia('(prefers-color-scheme: dark)').matches)
  const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
  darkModeMediaQuery.addListener(listener)
  // mql.addEventListener not supported on Safari and IE
  const unobserveDarkMode = (): void => darkModeMediaQuery.removeListener(listener)
  return unobserveDarkMode
}
