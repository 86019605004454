// @file Dark mode store
import { observeDarkMode } from '@@/bits/dark_mode'
import { debounce } from 'es-toolkit'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useDarkModeStore = defineStore('darkMode', () => {
  const isAutoDetectDarkMode = ref(true)
  const browserDarkModeValue = ref(false)
  const userDarkModeValue = ref(false)
  const isDarkMode = computed<boolean>(() =>
    isAutoDetectDarkMode.value ? browserDarkModeValue.value : userDarkModeValue.value,
  )
  const isBrowserDarkMode = computed(() => browserDarkModeValue.value)
  const unobserveDarkMode = observeDarkMode(
    debounce((newBrowserDarkModeValue) => (browserDarkModeValue.value = newBrowserDarkModeValue), 100),
  )

  const toggleDarkMode = (isDarkMode: boolean): void => {
    userDarkModeValue.value = isDarkMode
  }

  return {
    isDarkMode,
    /**
     * @deprecated Please use `usePreferredDark` from `@vueuse/core` instead.
     * The VueUse composable is more lightweight and doesn't require Pinia.
     */
    isBrowserDarkMode,
    unobserveDarkMode,
    toggleDarkMode,
  }
})
